import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import AdobeCampaignBanner from "../components/Platforms/AdobeCampaignBanner/AdobeCampaignBanner";
import AdobeCampaignVideo from "../components/Platforms/AdobeCampaignVideo/AdobeCampaignVideo";
import AdobeCampaignBenefits from "../components/Platforms/AdobeCampaignBenefits/AdobeCampaignBenefits";
import AdobeCampaignServices from "../components/Platforms/AdobeCampaignServices/AdobeCampaignServices";
// import styles from "./ux-design.module.scss";
import { Helmet } from "react-helmet";

function AdobeAnalytics() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout>
      <Helmet>
		 <meta charSet="utf-8" />
        <title>
		Hashout Technologies - Adobe Campaign
        </title>
        <meta
          name="description"
          content="Centralized place to create, automate, personalize and orchestrate the execution of mobile, social, email, and offline campaigns."
        ></meta></Helmet>
      <SolutionsHelmet />
      <AdobeCampaignBanner />
      <AdobeCampaignServices />
      <AdobeCampaignVideo />
      <AdobeCampaignBenefits />
      <LearnAboutUs page="adobe-compaign"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default AdobeAnalytics;
