import React from "react";

function AdobeCampaignBenefits() {
  return (
    <section className="section section--tools section--adobe-benefits section--bg-light-blue section--ldots-left-top-bg --section-medium-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Adobe Campaign benefits</h2>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/data-drive.png"
                alt="Data Drive"
              />
              <a>
                <h5 className="card__title">Data Drive</h5>
              </a>
              <p className="card__description">
                Deliver personalized experience consistently across the entire
                customer journey even if it moves from one channel to another.{" "}
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/cross-channel.png"
                alt="Cross-Channel"
              />
              <a>
                <h5 className="card__title">Cross-Channel</h5>
              </a>
              <p className="card__description">
                Integrate email, mobile online/offline channels into the
                customer journey
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/automation-orchestration.png"
                alt="Automation & Orchestration"
              />
              <a>
                <h5 className="card__title">Automation & Orchestration</h5>
              </a>
              <p className="card__description">
                Automate the delivery of meaningful and timely messages and
                offers
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AdobeCampaignBenefits;
